/**
 *
 * RemarkRenderer
 *
 */

import React from 'react'
import { useStoryblokEditable } from '@sb-konzept/gatsby-source-storyblok'
import cx from 'classnames'
import renderAst, { components } from './renderAst'
import Blockquote from './Blockquote'
import GradientTypography from './GradientTypography'

function RemarkRenderer ({ trimSingle, ast, editable }) {
  const { editableProps } = useStoryblokEditable()
  if (!ast) return null

  let renderedAst
  if (trimSingle && ast.children.length === 1) {
    const newAst = {
      ...ast,
      children: [{ ...ast.children[0], tagName: 'span' }]
    }
    renderedAst = renderAst(newAst)
  } else {
    renderedAst = renderAst(ast)
  }

  if (editable) {
    return React.cloneElement(renderedAst, {
      ...editableProps,
      className: cx(renderedAst.props.className, editableProps.className)
    })
  }

  return renderedAst
}

function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

RemarkRenderer.Blockquote = Blockquote
RemarkRenderer.GradientTypography = GradientTypography
Object.keys(components).forEach((compName) => {
  RemarkRenderer[capitalize(compName)] = components[compName]
})

export default RemarkRenderer
