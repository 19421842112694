import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import { map } from 'ramda'
import RehypeReact from 'rehype-react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import RemarkContext from './RemarkContext'
import Blockquote from './Blockquote'
import GradientTypography from './GradientTypography'

const useStyles = makeStyles({
  text: ({ removeLastGutter }) =>
    (removeLastGutter
      ? {
        '&:last-child': {
          marginBottom: 0
        }
      }
      : {})
})

function T (props) {
  const { gradientColor, removeLastGutter, ...rmProps } = useContext(
    RemarkContext
  )
  const classes = useStyles({ removeLastGutter })

  if (gradientColor) {
    return (
      <GradientTypography
        gradientVariant={gradientColor}
        className={classes.text}
        {...props}
        {...rmProps}
      />
    )
  }
  return <Typography className={classes.text} {...props} {...rmProps} />
}

function createComponents (config) {
  return map(extraProps => props => <T {...props} {...extraProps} />, config)
}

export const components = createComponents({
  h1: { component: 'h2', variant: 'h1', gutterBottom: true },
  h2: { variant: 'h2', gutterBottom: true },
  h3: { variant: 'h3', gutterBottom: true },
  h4: { variant: 'h4', gutterBottom: true },
  h5: { variant: 'h5', gutterBottom: true },
  h6: { variant: 'h6', gutterBottom: true },
  p: { paragraph: true, variant: 'body1' },
  span: { component: 'span' }
})

export const Rehype = new RehypeReact({
  createElement: React.createElement,
  components: {
    ...components,
    blockquote: Blockquote,
    table: Table,
    thead: () => null, // TableHead, // Disable thead for now
    tbody: TableBody,
    hr: Divider,
    tr: TableRow,
    td: props => (
      <TableCell padding="none" style={{ color: 'inherit' }} {...props} />
    ),
    th: props => (
      <TableCell padding="none" style={{ color: 'inherit' }} {...props} />
    )
  }
})

export default Rehype.Compiler
