import React, { useContext } from 'react'
import styled from 'styled-components'
import { withProp, theme } from 'styled-tools'
import Typography from '@material-ui/core/Typography'

import RemarkContext from './RemarkContext'

function BlockquoteImpl (props) {
  const { color, align } = useContext(RemarkContext)
  return (
    <Typography component="blockquote" color={color} align={align} {...props} />
  )
}

const spacing = mult => withProp('theme.spacing.unit', s => `${s * mult}px`)

const Blockquote = styled(BlockquoteImpl)`
  padding: ${spacing(6)} ${spacing(6)};
  quotes: "\\201C" "\\201C" "\\2018" "\\2018";
  position: relative;

  :before {
    content: open-quote;
    left: ${spacing(1)};
    top: ${spacing(6)};
  }
  :after {
    content: close-quote;
    right: ${spacing(1)};
    bottom: ${spacing(6)};
    transform: rotate(180deg);
  }
  :before,
  :after {
    position: absolute;
    display: block;
    color: ${theme('palette.secondary.main')};
    font-size: ${spacing(12)};
    line-height: 1px;
  }
`

export default Blockquote
