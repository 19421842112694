import React from 'react'
import styled from 'styled-components'
import { linearGradient } from 'polished'
import { withProp } from 'styled-tools'
import Typography from '@material-ui/core/Typography'

const gradientVariants = [
  withProp('theme.palette.gradient', gradient =>
    linearGradient({
      colorStops: [
        `${gradient.light} 0%`,
        `${gradient.main} 50%`,
        `${gradient.dark} 100%`
      ],
      toDirection: 'to right',
      fallback: gradient.main
    })
  )
]

const GradientTypography = styled(({ gradientVariant, ...rest }) => (
  <Typography {...rest} />
))`
  ${props => gradientVariants[props.gradientVariant]};
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`

export default GradientTypography
